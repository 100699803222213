import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Tab, Tabs } from '@mui/material';

import AccountsPage from '@/components/settings-pages/AccountsPage';
import ConnectedAccounts from '@/components/settings-pages/ConnectedAccounts';
import SubscriptionSettings from '@/components/settings-pages/SubscriptionSettings';
import Logout from '@/components/utility/auth/Logout';
import UserHeader from '@/components/utility/navigation/UserHeader';
import useIntercomContext from '@/hooks/context/useIntercomContext';

const SettingsPage = () => {
  const { t } = useTranslation();

  const { dispatchActivateIntercom, dispatchDeactivateIntercom, dispatchOpenIntercom } = useIntercomContext();

  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    if (value === 3) {
      dispatchActivateIntercom('settings');
      dispatchOpenIntercom();
    } else {
      dispatchDeactivateIntercom('settings');
    }
    return () => {
      dispatchDeactivateIntercom('settings');
    };
  }, [dispatchActivateIntercom, dispatchDeactivateIntercom, dispatchOpenIntercom, value]);

  return (
    <div data-testid="settings-page" className="page-content">
      <UserHeader title="PAGE-TITLES.SETTINGS" />
      <Box
        sx={{
          width: '100%',
          marginTop: '20px',
          marginBottom: '8px',
        }}
      >
        <Tabs value={value} onChange={(_, newValue) => setValue(newValue)} aria-label="basic tabs example">
          <Tab label={t('SETTINGS.ACCOUNT')} data-testid="your-details" />
          <Tab label={t('SETTINGS.CONNECTED-ACCOUNTS')} data-testid="payment-details" />
          <Tab label={t('SETTINGS.SUBSCRIPTIONS-AND-PURCHASES')} data-testid="security-settings" />
          <Tab label={t('SETTINGS.SUPPORT')} />
          <Tab className="min-w120 ml-auto" label={<Logout inButton={true} />}></Tab>
        </Tabs>
      </Box>
      {value === 0 && <AccountsPage />}
      {value === 1 && (
        <div>
          <p>{t('SETTINGS.NOTIFICATIONS')}</p>
        </div>
      )}
      {value === 1 && <ConnectedAccounts />}
      {value === 2 && <SubscriptionSettings />}
      {value === 3 && (
        <div className="t-and-c-footer">
          <div className="login-footer">
            <a
              href="https://www.unhurd.co.uk/terms-conditions"
              target="_blank"
              rel="noreferrer"
              className="text-right text-faded w50p fs-sm pr16 cursor-pointer"
            >
              {t('COMMON.TERMS-AND-CONDITIONS')}
            </a>
            <a
              href="https://www.unhurd.co.uk/privacy-policy"
              target="_blank"
              rel="noreferrer"
              className="w50p text-faded fs-sm cursor-pointer"
            >
              {t('COMMON.PRIVACY-POLICY')}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsPage;
